import SEO from '@cuidardigital/commons/components/Seo/seo'
import React from 'react'
import PageNewAssistant from '../../components/NewSecretary/'

export default (props: any) => (
	<>
		<SEO />
		<PageNewAssistant {...props} />
	</>
)
