export const passwordValidator = (value: string) => {
	/**
	 * this regex complies with below asserts:
	 * - minimum 8 char
	 * - maximum 32 char
	 * - initial letter must be capital Letter
	 * - must contain at least one number
	 */
	const reg = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\W|_]{8,}$/
	return value.length > 0 ? reg.test(value) : false
}
