import styled from 'styled-components'
import {
	InputError as SuperInputError,
	SubmitButtonRed as SuperSubmitButtonRed
} from '../superSetCommons'

interface IInput {
	error?: boolean
}

interface PropsFormFIeldWrapper {
	second?: boolean
	halfField?: boolean
}

export const FormLabelContainer = styled.div`
	display: flex;
	flex-direction: row;
`

export const InputError = styled(SuperInputError)``

export const Container = styled.div`
	background: #f7f7f7;
	min-height: 101vh;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Content = styled.div`
	max-width: 30%;
	background-color: #fff;
	box-shadow: 0px 0px 16px #00000019;
	border: 1px solid #e9e9e9;
	border-radius: 10px;
	padding: 25px 30px 50px 30px;

	@media (max-width: 1050px) {
		max-width: 55%;
	}

	@media (max-width: 480px) {
		max-width: 100%;
		padding 10px;
	}
`

export const FormWrapper = styled.div`
	padding: 40px;
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 16px #00000019;
	border: 1px solid #e9e9e9;
	border-radius: 10px;

	@media (max-width: 767.98px) {
		padding: 20px 0;
	}
`

export const FormFieldWrapper = styled.div<PropsFormFIeldWrapper>`
	margin: ${props => (props.second ? '0 0 0 28px' : props.halfField ? '0' : '14px auto 0')};
	width: ${props => (props.halfField ? '48%' : '100%')};
	display: ${props => (props.halfField ? 'inline-block' : 'block')};

	@media (max-width: 480px) {
		width: 100%;
		display: block;
		margin: 14px auto 0;
		padding: 0;
	}
`

export const FlexFieldWrapper = styled(FormFieldWrapper)`
	display: flex;

	@media (max-width: 480px) {
		width: 100%;
		display: block;
		padding: 0;
	}
`

export const FormLabel = styled.label`
	font: 14px/20px CentraleSansRegular;
	letter-spacing: 0;
	color: #333333;
	display: block;
	margin: 0 0 8px;
`

interface PropsFormInput {
	error?: boolean
	width?: string
	halfField?: boolean
}

export const PasswordInput = styled.input<PropsFormInput>`
	border: none;
	height: 48px;
	width: 96%;
`

export const FormInput = styled.input<PropsFormInput>`
	display: block;
	width: 100%;
	height: 48px;
	border: solid 1px ${props => (props.error ? '#FF0000' : '#CCCCCC')};
	padding: 0 16px;

	@media (max-width: 1340px) {
		font: 12px/32px CentraleSansRegular;
	}

	@media (max-width: 767.98px) {
		margin: 0;
		font: 10px/32px CentraleSansRegular;
	}

	@media (max-width: 480px) {
		width: 100%;
	}
`

export const Form = styled.div`
	margin: auto;
	padding: 0 20px;
`

export const LogoContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 30px 0;
`

export const Logo = styled.img`
	max-width: 60%;
`

export const ContainerCheckbox = styled.label`
	display: flex;
	// position: relative;
	// padding-left: 35px;
	margin: 15px 0 0 0;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 1.4;

	&:hover input ~ .checkSpan {
		border: 1px solid ${props => props.theme.colors.font_highlight}74;
		box-shadow: 0px 0px 6px ${props => props.theme.colors.font_highlight}74;
	}

	// input[type='checkbox'] {
	// 	position: absolute;
	// 	opacity: 0;
	// 	cursor: pointer;
	// 	height: 0;
	// 	width: 0;
	// }

	// .checkSpan {
	// 	position: absolute;
	// 	top: -2px;
	// 	left: 0;
	// 	height: 20px;
	// 	width: 20px;
	// 	background-color: ${props => props.theme.colors.transparent};
	// 	border: 1px solid ${props => props.theme.colors.button_disabled};
	// 	box-shadow: 0px 0px 6px ${props => props.theme.colors.button_disabled_border};
	// 	border-radius: 2px;
	// }

	// input:checked ~ .checkSpan {
	// 	// background-color: ${props => props.theme.colors.red_alert};
	// 	border: 1px solid ${props => props.theme.colors.font_highlight};
	// }

	// /* Create the checkmark/indicator (hidden when not checked) */
	// .checkSpan:after {
	// 	content: '';
	// 	position: absolute;
	// 	display: none;
	// }

	// /* Show the checkmark when checked */
	// input:checked ~ .checkSpan:after {
	// 	display: block;
	// }

	// /* Style the checkmark/indicator */
	// .checkSpan:after {
	// 	left: 6px;
	// 	top: -1px;
	// 	width: 5px;
	// 	height: 13px;
	// 	border: 1px solid ${props => props.theme.colors.font_highlight};
	// 	box-shadow: 0px 0px ${props => props.theme.colors.font_highlight}74;
	// 	border-width: 0 3px 3px 0;
	// 	-webkit-transform: rotate(45deg);
	// 	-ms-transform: rotate(45deg);
	// 	transform: rotate(45deg);
	// }
`

export const ConsentText = styled.p`
	text-align: left;
	font: 14px/20px CentraleSansRegular;
	letter-spacing: 0;

	a {
		text-align: left;
		text-decoration: underline;
		font: Bold 14px/20px CentraleSans-Bold;
		letter-spacing: 0;
		color: #748a96;
	}
`

export const SubmitButton = styled(SuperSubmitButtonRed)``

export const WelcomeText = styled.span`
	display: block;
	margin: 0px;
	text-align: center;
	letter-spacing: 0;
	color: ${props => props.theme.colors.font_dark};
	padding-left: 20px;

	strong {
		font-family: CentraleSans-Bold;
	}
`

export const FormInputContainer = styled.div<IInput>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	border: solid 1px ${props => (props.error ? '#FF0000' : '#CCCCCC')};
	padding: 0 16px;

	@media (max-width: 1340px) {
		font: 12px/32px CentraleSansRegular;
	}

	@media (max-width: 767.98px) {
		margin: 0;
		font: 10px/32px CentraleSansRegular;
	}
`

export const InputContainer = styled.div`
	width: 95%;
`

export const Eye = styled.img``

export const IconContainer = styled.div``

export const MessageContainer = styled.div``

export const WelcomeTitle = styled.p`
	text-align: left;
	padding: 0 20px;
`

export const TermsText = styled.strong`
	color: #0b67bd;
	font-weight: 900;
`
