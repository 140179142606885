import base64 from 'base-64'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import utf8 from 'utf8'

import { Loader } from '@cuidardigital/commons/components/Loader'
import Tooltip from '@cuidardigital/commons/components/Tooltip'
import {
	assistantCreate,
	getAssistantCreateCpf,
	getAssistantCreateDoctorName,
	getAssistantCreateEmail,
	getAssistantCreateName,
	getAssistantCreateToken,
	logout
} from '@cuidardigital/commons/services/auth'
import {
	confirmSecretaryRegistration,
	createSecretary
} from '@cuidardigital/commons/services/secretary.services'
import { passwordValidator } from '@cuidardigital/commons/utils/validator'

import closedEye from '@cuidardigital/commons/assets/svg/icon-eye-off.svg'
import openedEye from '@cuidardigital/commons/assets/svg/icon-eye-open.svg'
import IAssistant from '@cuidardigital/commons/types/IAssistant'
import isValidCPF from '@cuidardigital/commons/utils/cpf'
import { validateDateBirthday } from '@cuidardigital/commons/utils/date'
import { formatCPF, formatDate, formatPhone } from '@cuidardigital/commons/utils/masks'
import { isPhoneValid } from '@cuidardigital/commons/utils/phone'
import Checkbox from '@cuidardigital/commons/components/Checkbox'
import logo from '../../assets/img/logo.png'
import {
	ConsentText,
	Container,
	ContainerCheckbox,
	Content,
	Eye,
	FlexFieldWrapper,
	Form,
	FormFieldWrapper,
	FormInput,
	FormInputContainer,
	FormLabel,
	FormLabelContainer,
	IconContainer,
	InputError,
	Logo,
	LogoContainer,
	MessageContainer,
	PasswordInput,
	SubmitButton,
	TermsText,
	WelcomeText,
	WelcomeTitle
} from './styles'
import useCustomState from '../../hooks/useState'
import { NEW_ASSISTANT } from '../../routes'
import MessageModal from '../Modal'

interface Props {
	location: ObjectLiteral
}
interface ObjectLiteral {
	[key: string]: any
}
interface AffiliateProps {
	error: boolean
}

interface IModalObj {
	title?: string
	message: string
	buttonText?: string
	onClose?: () => void
}

const passwordTooltip = `
 A sua senha deve ter no mínimo 8 caracteres contendo pelo menos: 1 letra maiúscula e 1 número.
`

const AffiliateMessage: React.FC<AffiliateProps> = ({ error }) => {
	return error ? (
		<>
			Olá! Alguma coisa deu errado :( <br />
			Tente novamente em alguns instantes
		</>
	) : (
		<>
			Olá! Você já possui cadastro conosco. <br />
			Seu vínculo ao médico foi realizado com sucesso.
		</>
	)
}

const Success = ({ logout }) => (
	<>
		<WelcomeText wrap fontSize='16px/32px'>
			<strong>Cadastro confirmado!</strong>
			<br />
			Seu cadastro foi confirmado com sucesso.
		</WelcomeText>
		<SubmitButton onClick={logout}>Ok, entendi</SubmitButton>
	</>
)

const formInitialState: IAssistant = {
	password: '',
	password2: '',
	token: getAssistantCreateToken() || '',
	optins: false,
	name: '',
	email: '',
	cpf: '',
	phone: '',
	birthDay: ''
}
const errorsInitialState: any = {
	...Object.keys(formInitialState).reduce((value, item) => ({ ...value, [item]: false }), {}),
	token: false,
	doctorName: false
}

const Register: React.FC<Props> = ({ location }) => {
	const [state, setState] = useCustomState({ isSecretaryId: false, linkingError: false })

	const [formValues, setFormValues] = useState(formInitialState)
	const [errors, setErrors] = useState(errorsInitialState)
	const [formIsWrong, setFormIsWrong] = useState(true)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(true)
	const [showMessage, setShowMessage] = useState(false)
	const [messageToShow, setMessageToShow] = useState<IModalObj>({ message: '' })
	const [success, setSuccess] = useState(false)
	const [revealPassword, setRevealPassword] = useState({
		password: false,
		password2: false
	})

	useEffect(() => {
		initializeComponent()
	}, [])

	useEffect(() => {
		normalizerErrors()
	}, [errors])

	useEffect(() => {
		handleValidatePassword()
	}, [formValues.password, formValues.password2])

	const initializeComponent = async () => {
		try {
			const qsValue: { param?: string; sms?: string } = queryString.parse(location.search)
			if (qsValue && qsValue.param) {
				const bytes = base64.decode(qsValue.param)
				const { token, email, name, secretaryId, secretaryCpf, doctorName } = JSON.parse(
					utf8.decode(bytes)
				)
				// Informe no GA que a página foi acessada via SMS
				if (qsValue.sms) {
					ReactGA.event({
						category: 'SMS',
						action: 'Secretária Cadastro',
						label: `${name} ${
							secretaryId ? 'confirmando vínculo com' : 'cadastrando para'
						} ${doctorName}`
					})
				}
				if (secretaryId) {
					setState({ isSecretaryId: true })
					confirmSecretaryRegistration({ secretaryId })
						.catch(e => {
							setState({ linkingError: true })
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: `Não foi possível realizar sua solicitação. ${JSON.stringify(e)}`,
								onClose: setShowMessage
							})
						})
						.finally(() => {
							navigate(NEW_ASSISTANT)
							setLoading(false)
						})
				} else {
					assistantCreate({ token, email, name, cpf: secretaryCpf })
					setFormValues({
						...formValues,
						email,
						name,
						token,
						cpf: secretaryCpf,
						doctorName
					})
					setTimeout(() => setLoading(false), 500)
					navigate(NEW_ASSISTANT)
				}
			} else if (!formValues.token) {
				logout()
			} else {
				setTimeout(() => setLoading(false), 500)
				setFormValues({
					...formValues,
					cpf: getAssistantCreateCpf() || '',
					doctorName: getAssistantCreateDoctorName() || '',
					email: getAssistantCreateEmail() || '',
					name: getAssistantCreateName() || ''
				})
			}
		} catch {
			logout()
		}
	}

	const handleClearForm = () => {
		setFormValues(formInitialState)
		setErrors(errorsInitialState)
	}

	const handleRevealPassword = (param: 'password' | 'password2') => {
		setRevealPassword(prev => ({ ...prev, [param]: !revealPassword[param] }))
	}

	const handleConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { checked } = e.target
		setFormValues({ ...formValues, optins: checked })
		setErrors({ ...errors, consent: !checked })
	}

	const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { name } = formValues
		if (name !== value) {
			setFormValues({ ...formValues, name: value })
			setErrors({ ...errors, name: value.length === 0 })
		}
	}

	const handleCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { cpf } = formValues
		if (cpf !== value) {
			const newCpf = formatCPF(value)
			setFormValues({ ...formValues, cpf: newCpf })
			setErrors({
				...errors,
				cpf: !isValidCPF(
					value
						.replace('.', '')
						.replace('.', '')
						.replace('-', '')
				)
			})
		}
	}

	const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { phone } = formValues
		const fPhone = formatPhone(value)
		if (phone !== fPhone) {
			setFormValues({ ...formValues, phone: fPhone })
			setErrors({ ...errors, phone: !isPhoneValid(fPhone) })
		}
	}

	const handleBirthdate = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { birthDay } = formValues
		const fDate = formatDate(value)
		if (birthDay !== fDate) {
			setFormValues({ ...formValues, birthDay: fDate })
			setErrors({ ...errors, birthDay: !validateDateBirthday(fDate) })
		}
	}

	const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { password } = formValues
		if (password !== value) {
			setFormValues({ ...formValues, password: value })
			// handleValidatePassword(value)
			// setErrors({ ...errors, password: value.length < 8 })
		}
	}

	const handlePassword2 = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target
		const { password2, password } = formValues
		if (password2 !== value) {
			setFormValues({ ...formValues, password2: value })
			setErrors({ ...errors, password2: password !== value })
		}
	}

	const handlePasswordBlur = e => {
		const { value } = e.target
		const isValid = passwordValidator(value)
		setErrors({ ...errors, password: !isValid })
	}

	const handlePassword2Blur = e => {
		const { value } = e.target
		const { password } = formValues
		setErrors({ ...errors, password2: password !== value })
	}

	const handleValidatePassword = () => {
		const { password, password2 } = formValues
		if (password && password.length > 7) {
			const isValid = passwordValidator(password)
			setErrors({ ...errors, password: !isValid, password2: password2 !== password })
		}
	}

	const normalizerErrors = () => {
		const wrong = Object.values(errors).some(b => b) || Object.values(formValues).some(v => !v)
		const isValid = passwordValidator(formValues.password)
		setFormIsWrong(wrong || !isValid)
	}

	const handleBackHome = () => logout()

	const submitForm = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault()
		if (state.isSecretaryId) {
			logout()
		} else if (!formValues.cpf || !formValues.birthDay || !formValues.phone || !formValues.name) {
			setError('Por favor, preencha e-mail e senha para continuar.')
		} else {
			try {
				setLoading(true)
				createSecretary({ ...formValues })
					.then(response => {
						if (response) {
							if (response.status === 204) {
								// setMessageToShow({
								// 	title: 'Tudo pronto',
								// 	message: 'Cadastro completo!/n Agora basta realizar o login na plataforma quando for começar',
								// 	buttonText: 'Ok, entendi',
								// 	onClose: logout
								// })
								// deleteAssistantCreate()
								// handleClearForm()
								setSuccess(true)
							} else {
								setMessageToShow({
									title: 'Pedimos desculpas',
									message: response.data.message,
									onClose: setShowMessage
								})
								setShowMessage(true)
							}
						} else {
							setMessageToShow({
								title: 'Pedimos desculpas',
								message: 'Algo inesperado aconteceu',
								onClose: setShowMessage
							})
							setShowMessage(true)
						}
					})
					.catch(e => {
						setMessageToShow({
							title: 'Pedimos desculpas',
							message: `Não foi possível realizar sua solicitação. ${JSON.stringify(e)}`,
							onClose: setShowMessage
						})
						setShowMessage(true)
					})
					.finally(() => {
						// setShowMessage(true)
						setLoading(false)
					})
			} catch (error) {
				setLoading(false)
				setError('Houve um problema no fluxo de login.')
			}
		}
	}

	if (error !== '') {
		setMessageToShow({
			message: error,
			onClose: setShowMessage
		})
		setShowMessage(true)
		setError('')
	}

	if (loading) return <Loader />
	return (
		<>
			{showMessage && <MessageModal maxWidth='420px' {...messageToShow} />}
			<Container>
				<Content>
					<LogoContainer>
						<Logo src={logo} />
					</LogoContainer>
					{success ? (
						<Success logout={logout} />
					) : (
						<>
							{state.isSecretaryId ? (
								<WelcomeText wrap fontSize='16px/32px'>
									<AffiliateMessage error={state.linkingError} />
								</WelcomeText>
							) : (
								<MessageContainer>
									<WelcomeTitle>
										{`O(a) médico ${formValues.doctorName ||
											''} iniciou o seu cadastro como profissional de secretariado dele(a) na plataforma. Por
										favor, complete o seu cadastro.`}
									</WelcomeTitle>
								</MessageContainer>
							)}

							{!state.isSecretaryId && (
								<>
									<Form>
										<FormFieldWrapper>
											<FormLabel>Nome</FormLabel>
											<FormInput
												type='text'
												id='name'
												name='name'
												value={formValues.name}
												error={errors.name}
												onChange={handleName}
												placeholder='Nome'
											/>
											{errors.name && (
												<InputError fontSize='12px/18px'>Nome é obrigatório</InputError>
											)}
										</FormFieldWrapper>
										<FormFieldWrapper>
											<FormLabel>CPF</FormLabel>
											<FormInput
												type='text'
												id='cpf'
												name='cpf'
												value={formValues.cpf}
												error={errors.cpf}
												onChange={handleCpf}
												placeholder='000.000.000-00'
												disabled
											/>
											{errors.cpf && <InputError fontSize='12px/18px'>Cpf inválido</InputError>}
										</FormFieldWrapper>
										<FlexFieldWrapper>
											<FormFieldWrapper halfField>
												<FormLabel>Telefone</FormLabel>
												<FormInput
													type='text'
													id='phone'
													name='phone'
													value={formValues.phone}
													onChange={handlePhone}
													placeholder='(00) 00000-0000'
													error={errors.phone}
													halfField
												/>
												{errors.phone && (
													<InputError fontSize='12px/18px'>Telefone inválido</InputError>
												)}
											</FormFieldWrapper>
											<FormFieldWrapper halfField second>
												<FormLabel>Data de nascimento</FormLabel>
												<FormInput
													type='text'
													id='birthDay'
													name='birthDay'
													value={formValues.birthDay}
													onChange={handleBirthdate}
													placeholder='00/00/0000'
													error={errors.birthDay}
													halfField
												/>
												{errors.birthDay && (
													<InputError fontSize='12px/18px'>Data inválida</InputError>
												)}
											</FormFieldWrapper>
										</FlexFieldWrapper>
										<FormFieldWrapper>
											<FormLabelContainer>
												<FormLabel>Senha</FormLabel>
												<Tooltip content={passwordTooltip} />
											</FormLabelContainer>
											<FormInputContainer>
												<PasswordInput
													id='password'
													name='password'
													error={errors.password}
													value={formValues.password}
													onChange={handlePassword}
													onBlur={handlePasswordBlur}
													placeholder='Digite aqui a sua senha'
													halfField
													type={revealPassword.password ? 'text' : 'password'}
												/>
												<IconContainer>
													<Eye
														src={revealPassword.password ? openedEye : closedEye}
														// tslint:disable-next-line: jsx-no-lambda
														onClick={() => handleRevealPassword('password')}
													/>
												</IconContainer>
											</FormInputContainer>
											{errors.password && (
												<InputError fontSize='12px/18px'>Senha inválida</InputError>
											)}
										</FormFieldWrapper>
										<FormFieldWrapper>
											<FormLabel>Confirmar Senha</FormLabel>
											<FormInputContainer>
												<PasswordInput
													id='password2'
													name='password2'
													error={errors.password2}
													value={formValues.password2}
													onChange={handlePassword2}
													onBlur={handlePassword2Blur}
													placeholder='Confirme sua senha'
													halfField
													type={revealPassword.password2 ? 'text' : 'password'}
												/>
												<IconContainer>
													<Eye
														src={revealPassword.password2 ? openedEye : closedEye}
														// tslint:disable-next-line: jsx-no-lambda
														onClick={() => handleRevealPassword('password2')}
													/>
												</IconContainer>
											</FormInputContainer>
											{errors.password2 && (
												<InputError fontSize='12px/18px'>
													{errors.password ? 'Senha inválida' : 'Senhas não conferem'}
												</InputError>
											)}
										</FormFieldWrapper>
										<FormFieldWrapper>
											<ContainerCheckbox>
												<Checkbox
													id='checkbox-use-term'
													onChange={handleConsent}
													checked={formValues.optins}
												/>
												<ConsentText>
													Aceito os
													<span>
														<a
															href='https://cdn.cosmicjs.com/f8b06d10-b4ba-11ea-b4fa-f3dcd232bf73-TERMO-DE-USO-SECRETARIA.pdf'
															rel='noopener noreferrer'
															target='_blank'
														>
															<TermsText> Termos de uso </TermsText>
														</a>
													</span>
													da Bradesco Saúde.
												</ConsentText>
											</ContainerCheckbox>
										</FormFieldWrapper>
									</Form>
								</>
							)}
							<SubmitButton
								error={state.isSecretaryId ? false : formIsWrong}
								disabled={state.isSecretaryId ? !state.isSecretaryId : formIsWrong}
								onClick={submitForm}
							>
								{state.isSecretaryId ? 'Retornar à página inicial' : 'Confirmar'}
							</SubmitButton>
						</>
					)}
				</Content>
			</Container>
		</>
	)
}

export default Register
