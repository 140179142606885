import { useState as State } from 'react'

type InitialState = any

const useState = (initialState: InitialState) => {
	const [state, setState] = State(initialState)

	const handleState = (nextState: InitialState) =>
		setState((prevState: InitialState) => ({ ...prevState, ...nextState }))

	return [state, handleState]
}

export default useState
